/*-----------------------------------------------
|   Lottie
-----------------------------------------------*/
.lottie{
  width: 5rem;
  height: 5rem;
}
.ie{
  .wizard-lottie{
    height: 37.5rem;
  }
}